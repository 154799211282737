import anime from 'animejs/lib/anime.es';

export class Bubbler {

    // readonly NUMBEROFBUBBLES: number = 50;

    bubbles: Array<Element> = [];

    bubble: HTMLElement = document.createElement("div");

    constructor() {
        this.bubble.className = "content__bubble"
    }


    generateBubblesFor(element: HTMLElement): void {
        let rect: DOMRect = element.getBoundingClientRect();

        let sectionWidth: number = rect.width / 5;
        let sectionHeight: number = rect.height / 5;
        element.innerHTML = "";
        this.bubbles = [];
        for (let i = 0; i < rect.width; i += sectionWidth) {
            for (let j = 0; j < rect.height; j += sectionHeight) {
                let tempBubble: HTMLElement = this.bubble.cloneNode() as HTMLElement;
                tempBubble.style.top = (j +anime.random(0, sectionHeight)) +"px";
                tempBubble.style.left = (i +anime.random(0, sectionWidth))+ "px";
                let size: number = anime.random(80, 400);
                tempBubble.style.width = size + "px";
                tempBubble.style.height = size + "px";
                this.bubbles.push(tempBubble);
                element.append(tempBubble);
            }
        }

    }

    animateBubbles() {
        anime({
            targets: this.bubbles,
            translateY: [
                {
                    value: this.getRelRandomTranslateValue,
                }

            ],
            translateX: [
                {
                    value: this.getRelRandomTranslateValue,
                }
            ],
            scale: [
                {
                    value: this.getRelRandomSizeValue
                }
            ],
            duration: 5000,
            complete: function (anim) {
                this.animateBubbles();
            }.bind(this), // TODO: fix this bind?
            easing: 'cubicBezier(0.475, -0.005, 0.490, 1.005)'
        });
    }

    getRelRandomTranslateValue(): string {
        return "+=" + anime.random(-40, 40)
    }


    getRelRandomSizeValue(): string {
        return "+=" + (anime.random(-20, 20) / 100)
    }
}