import {Bubbler} from "./bubbler";
import anime from 'animejs/lib/anime.es';


let bubbler = new Bubbler();


document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

bubbler.generateBubblesFor(document.getElementById('test'));
bubbler.animateBubbles();

window.addEventListener("resize", () =>{
    bubbler.generateBubblesFor(document.getElementById('test'));
});

let textWrapper = document.querySelector('.header__mainname--name');
textWrapper.innerHTML = textWrapper.textContent.replace(/([^\x00-\x80]|\w|\s)/g, "<span class='letter'>$&</span>");

// Blinking
anime({
    targets: ".header__mainname--line",
    opacity: [
        {value: [1,1], duration: 500},
        {value: [0,0], duration: 500}
    ],
    loop: true,
    easing: 'linear'
})

// Letters
anime({
    targets: ".header__mainname--name .letter",
    opacity: 1,
    'max-width': {value:'500px', duration:20, delay: anime.stagger(650)},
    'min-width': {value:'15px', duration:20, delay: anime.stagger(650)},//Space
    delay: anime.stagger(650),
})

